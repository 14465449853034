import React, { Component } from 'react';
import BuildingGridItem from '../BuildingGridItem/BuildingGridItem';
import ContentGrid from '../../../layout/ContentGrid/ContentGrid';

export default class PropertyGridWidget extends Component {

    render() {

        let items = [];

        if(this.props.buildings instanceof Array) {
            for(const item of this.props.buildings) {
                if(item.get('market_id') === this.props.market) {
                    items.push(<BuildingGridItem key={item.get('id')} building={item} isCustomerLink={this.props.isCustomerGrid} />);
                }
            }
        }

        return (
            <div className="property-grid-widget">
                <ContentGrid small={12} medium={4} large={3}>{items}</ContentGrid>
            </div>
        );

    }

}