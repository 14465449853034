import React, { Component } from 'react';
import BuildingMasthead from '../../components/content/building/BuildingMasthead/BuildingMasthead';
import GoogleMap from '../../components/ui/Map/GoogleMap/GoogleMap';
import BuildingFacts from '../../components/content/building/BuildingFacts/BuildingFacts/BuildingFacts';
import Gallery from '../../components/content/property/PropertyGallery/Gallery/Gallery';
import Entity from '../../drupal/Entity';
import Include from '../../drupal/Include';
import Meta from '../../components/atoms/Meta/Meta';
import BuildingFactGrid from '../../components/content/building/BuildingFacts/BuildingFactGrid/BuildingFactGrid';
import PositionGrid from '../../components/content/position/PositionGrid/PositionGrid';

export default class PropertyDetailPage extends Component {

    state = {
        currentBuilding: null
    };

    componentDidMount = async () => {
        const buildings = this.props.entity.get('field_buildings');
        let defaultBuilding = buildings[0];

        const query = new URLSearchParams(window.location.search);

        if(query.get('building')) {
            const building = buildings.filter(obj => {
                return obj.get('field_url_alias') === query.get('building');
            });

            if(building.length > 0) {
                defaultBuilding = building[0];
            }
        }

        this.setState({
            currentBuilding: defaultBuilding
        });

    }
    
    render() {
        let buildingMasthead = <BuildingMasthead placeholder={true} />
        let positionGrid = null;
        let googleMap = null;
        let buildingFacts = null;
        let imageCarousel = null;
        let isCustomerSection = false;

        const urlParts = window.location.pathname.replace(/^\/+|\/+$/g, '').split('/');
        if(urlParts[0] === 'customer') {
            isCustomerSection = true;
        }

        if(this.props.entity instanceof Entity) {
            
            const buildings = this.props.entity.get('field_buildings');
            let places = [];
            for(let building of buildings) {
                places = this.processBuildingPlaces(building, places);
            }

            googleMap = (
                <div className="page-section">
                    <GoogleMap places={places} defaultZoom={this.props.entity.get('field_default_map_zoom_level')} outlet={this.props.outlet} />
                </div>
            );

            if(isCustomerSection) {

                buildingMasthead = <BuildingMasthead isCustomerSection={isCustomerSection} handleBuildingChange={this.handleBuildingChange} currentBuilding={this.state.currentBuilding} entity={this.props.entity} alternateContent='customer' />

                if(this.state.currentBuilding !== null && this.state.currentBuilding.get('field_customer_positions').length > 1) {
                    positionGrid = (
                        <div className="page-section padded">
                            <PositionGrid featured={true} positions={this.state.currentBuilding.get('field_customer_positions')} entity={this.props.entity} label={this.state.currentBuilding.get('field_positions_label')} />
                        </div>
                    );
                }
    
                buildingFacts = <BuildingFactGrid entity={this.props.entity} currentBuilding={this.state.currentBuilding} />;

            } else {

                buildingMasthead = <BuildingMasthead isCustomerSection={isCustomerSection} handleBuildingChange={this.handleBuildingChange} currentBuilding={this.state.currentBuilding} entity={this.props.entity} drupal={this.props.drupal} />

                if(this.state.currentBuilding instanceof Include && this.state.currentBuilding.get('field_positions') instanceof Array && this.state.currentBuilding.get('field_positions').length > 1) {
                    positionGrid = (
                        <div className="page-section padded">

                            <div className="row">
                                <div className="col-lg-3"></div>
                                <div className="col-lg-9">
                                    <PositionGrid featured={true} positions={this.state.currentBuilding.get('field_positions')} entity={this.props.entity} label={this.state.currentBuilding.get('field_positions_label')} />
                                </div>
                            </div>

                        </div>
                    );
                }
    
                buildingFacts = <BuildingFacts entity={this.props.entity} currentBuilding={this.state.currentBuilding} />;

                if(this.state.currentBuilding instanceof Include && this.state.currentBuilding.get('field_galleries') instanceof Array && this.state.currentBuilding.get('field_galleries').length > 0) {
                    imageCarousel = (
                        <div className="page-section padded">
                            <Gallery key={this.state.currentBuilding.id} galleries={this.state.currentBuilding.get('field_galleries')} entity={this.props.entity} currentBuilding={this.state.currentBuilding} />
                        </div>
                    );
                }
            }
        }

        const meta = <Meta data={this.props.entity.get('meta')} />

        return (
            <div className="property-detail-page">
                {meta}

                <div className="page-content container">

                    <div className="page-section padded">
                        {buildingMasthead}
                    </div>

                    {positionGrid}

                    <div className="page-section padded">
                        <div className="row">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-9">
                                {buildingFacts}
                            </div>
                        </div>
                    </div>

                </div>

                {imageCarousel}

                {googleMap}

            </div>
        );

    }

    handleBuildingChange = (selectorItem) => {
        this.setState({
            currentBuilding: selectorItem.props.children.props.building
        });
    }

    processBuildingPlaces = (building, places) => {
        places.push({
            name: building.get('field_name'),
            lat: building.get('field_geolocation').lat,
            lng: building.get('field_geolocation').lng,
            type: 'building',
            id: building.get('id')
        });

        for(let place of building.get('field_places')) {
            places.push({
                name: place.get('title'),
                lat: place.get('field_geolocation').lat,
                lng: place.get('field_geolocation').lng,
                type: place.get('field_place_type'),
                id: place.get('id')   
            });
        }

        return places;
    }

}