import React, { Component } from 'react';
import Button from '../../../atoms/Button/Button';

import './HeroItem.css';

export default class HeroItem extends Component {

    render() {
        let inlineStyle = {};
        let title = null;
        let subtitle = null;
        let longText = null;
        let link = null;
        let video = null;
        let alternateClass = null;

        if(this.props.item instanceof Object) {
            if(this.props.item.get('field_image').get('field_media_image')) {
                inlineStyle.backgroundImage = `url('${this.props.item.get('field_image').get('field_media_image').get('links').hd1080.href}')`;
            } else if(this.props.item.get('field_image').get('field_media_file')) {
                video = (
                    <video autoPlay playsInline={true} muted loop>
                        <source src={this.props.drupal.getBaseUrl() + this.props.item.get('field_image').get('field_media_file').get('uri').url} type="video/mp4" />
                    </video>
                );
            }

            if(this.props.item.get('field_title')) {
                title = (
                    <h1>{this.props.item.get('field_title')}</h1>
                );
            }

            if(this.props.item.get('field_subtitle')) {
                subtitle = (
                    <h4>{this.props.item.get('field_subtitle')}</h4>
                );
            }

            if(this.props.item.get('field_long_text')) {
                longText = (
                    <p>{this.props.item.get('field_long_text')}</p>
                );
            }

            if(this.props.item.get('field_link')) {
                link = (
                    <Button buttonClasses='dark ghost' url={this.props.get('field_link').uri} text={this.props.item.get('field_link').title} />
                );
            }

            if(this.props.item.get('field_alternate_styling')) {
                alternateClass = "alternate-styling";
            }
        }

        return (
            <div className={`hero-item ${alternateClass}`} style={inlineStyle}>
                <div className="underlay"></div>
                <div className="overlay"></div>
                {video}
                <div className="hero-item-content container">
                    <div className="hero-item-title">
                        {title}
                    </div>

                    <div className="hero-item-details">
                        {subtitle}
                        {longText}
                        {link}
                    </div>
                </div>
            </div>
        );
    }
}
