import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import Include from '../../../../drupal/Include';

import './BuildingGridItem.css';

export default class BuildingGridItem extends Component {

    render() {

        let image = this.props.building.get('field_teaser_image');

        if(image) {
            const imageUrl = image.get('field_media_image')?.get('links')?.medium_square.href;
            image = <Image src={imageUrl} alt={image.get('field_media_image').get('alt')} />;
        }

        let linkUrl = `/customer${this.props.building.get('property_url')}`;

        if(this.props.building.get('field_url_alias')) {
            linkUrl += `?building=${this.props.building.get('field_url_alias')}`;
        }

        let outlet = this.props.building.get('field_outlet');

        if(outlet instanceof Include && outlet.get('status') === true) {
            const outletDomain = outlet.get('name');
            linkUrl = `https://${outletDomain}`;
        }

        return (
            <div className="building-grid-item">
                <a target="_blank" rel="noreferrer noopener" href={linkUrl}>
                    {image}
                    {this.props.building.get('field_name')}
                </a>
            </div>
        );

    }

}