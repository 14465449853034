import React, { Component } from 'react';
import HeroItem from '../HeroItem/HeroItem';
import Slider from 'react-slick';

import './Hero.css';

export default class Hero extends Component {

    render() {
        let items = [];

        for(const item of this.props.items) {
            items.push(<HeroItem key={item.get('id')} drupal={this.props.drupal} item={item} />)
        }

        if(items.length > 1) {
            const sliderSettings = {
                dots: true,
                arrows: false,
                infinite: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                autoplay: true,
                dotsClass: 'slick-dots container'
            };
    
            items = <Slider {...sliderSettings}>{items}</Slider>;
        }

        return (
            <div className="hero page-section padded">
                {items}
            </div>
        );
    }
}
