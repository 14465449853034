import React, { Component } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BuildingSwitcher from '../BuildingSwitcher/BuildingSwitcher';
import PositionCard from '../../position/PositionCard/PositionCard';
import LocalVideo from '../../media/LocalVideo/LocalVideo';
import RemoteVideo from '../../media/RemoteVideo/RemoteVideo';
import Connection from '../../../../drupal/Connection';
import Include from '../../../../drupal/Include';

import './BuildingMasthead.css';

export default class BuildingMasthead extends Component {

    render() {
        if(this.props.currentBuilding instanceof Include) {
            let mainImageComponent = null;
            let buildingSwitcher = <BuildingSwitcher currentBuilding={this.props.currentBuilding} handleBuildingChange={this.props.handleBuildingChange} entity={this.props.entity} />;

            const mainImageMedia = this.props.currentBuilding.get('field_main_image');
            if(mainImageMedia.get('type') === 'media--image') {
                const mainImage = mainImageMedia.get('field_media_image');
                mainImageComponent = <Image src={mainImage.get('links').property_detail_main.href} alt={mainImageMedia.get('field_media_image').alt} />;
            } else if(mainImageMedia.get('type') === 'media--local_video') {
                const mainImage = mainImageMedia.get('field_media_file');
                mainImageComponent = <LocalVideo key={mainImageMedia.get('id')} url={this.props.drupal.getBaseUrl() + mainImage.get('uri').url} />;
            } else if(mainImageMedia.get('type') === 'media--video') {
                mainImageComponent = <RemoteVideo url={mainImageMedia.get('field_media_oembed_video')} />;
            }

            let marketName = <Link to={this.props.entity.get('field_market').get('url')}>{this.props.entity.get('field_market').get('title')}</Link>;
            if(this.props.isCustomerSection) {
                marketName = <Link to={`/customers?market=${this.props.entity.get('field_market').get('id')}`}>{this.props.entity.get('field_market').get('title')}</Link>
            }

            let featuredPositionCard = null;

            if(this.props.alternateContent) {

                if(this.props.currentBuilding.get('field_customer_positions').length > 0) {
                    const featuredPosition = this.props.currentBuilding.get('field_customer_positions')[0];
                    const featuredPerson = featuredPosition.get('field_person');
                    if(featuredPerson && featuredPerson.get('status')) {
                        featuredPositionCard = <PositionCard featured={true} position={featuredPosition} entity={this.props.entity} />
                    }
                }
    
            } else {

                if(this.props.currentBuilding.get('field_positions').length > 0) {
                    const featuredPosition = this.props.currentBuilding.get('field_positions')[0];
                    const featuredPerson = featuredPosition.get('field_person');
                    if(featuredPerson && featuredPerson.get('status')) {
                        featuredPositionCard = <PositionCard featured={true} position={featuredPosition} entity={this.props.entity} />
                    }
                }

            }
            
            return (
                <div className="building-masthead row">

                    <div className="col-lg-3">
                        <div className="market">
                            {marketName}
                        </div>

                        <h1 className="property-title">
                            { this.props.entity.get('title') }
                        </h1>

                        {buildingSwitcher}
                        {featuredPositionCard}
                    </div>

                    <div className="col-lg-9 main-image">
                        {mainImageComponent}
                    </div>

                </div>
            );
        } else {
            return (
                <></>
            );
        }
    }
}